<template>
  <div>
    <p v-html="text"></p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
/* https://css-houdini.rocks/highlighter-marker-annotations/ */
sfhighlight {
  font-weight: bolder;
}
</style>