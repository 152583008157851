<template>
  <div>
    <v-card :color="colorBg" :to="{ name: routeTo }">
      <v-card-text v-html="text" @click="emitEvent()" class="button_text"> </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  props: {
    text: {
      type: String,
    },
    routeTo: {
      type: String,
    },
    triggerEvent: {
      type: String
    },
    colorBg: {
      type:String,
      defualt:"secondary"
    }
  },
  components: {},
  data: () => ({}),
  methods: {
    emitEvent() {
      if (this.triggerEvent) {
        EventBus.$emit(this.triggerEvent)
      }
    }
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoepd>
.button_text {
  font-weight: bold;
  font-size: 1rem;
  color: black;
}
</style>